import {
  BookingPackage,
  BookingPackageAddress,
  BookingPackageDossier,
  BookingPackageFlight,
  BookingPackageFlightPool,
  BookingPackageHotel,
  BookingPackageItem,
  BookingPackageLine,
  BookingPackagePax,
  BookingPackageRequestRoom,
  BookingPackageRoomOption,
  BookingPriceDetails,
  CountryItem,
  PerBookingPackageOption,
  PerUnitPackageOption,
  TideResponse,
} from "@qite/tide-client/build/types";
import { ServiceType } from "@qite/tide-client";
import { navigate } from "gatsby";
import { cloneDeep, first, groupBy, isEmpty, isEqual, orderBy, sumBy } from "lodash";
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  bookPackage,
  searchPackageDetail,
  searchPackagePriceDetail,
  DEFAULT_ROOM,
  getFlightPool,
  searchSpecific,
  getTicketAvailabilities,
  getTourcodes,
} from "../../services/product-service";
import { TideItemForFacility, TideTag } from "../../types";
import GlobalContext, { GlobalContextType } from "../global-provider";
import { differenceInCalendarDays } from "date-fns/esm";
import { BookingPackageAvailability } from "@qite/tide-client/build/types/offer/booking-v2/shared/booking-package-availability";
import { searchCountries } from "../../services/search-service";
import {
  createDataLayerItemsFromDetails,
  createDataLayerItemsFromSearch,
  flightReduction,
  hotelReduction,
  ticketReduction,
} from "../../utils/package-utils";

interface RoomDistributionError {
  key: string;
  text: string;
}

interface BookingProviderProps {
  queryParameters: URLSearchParams;
  tradeTrackerTag: TideTag;
}

interface BookingEventRequest {
  eventId: string;
  agentId?: number;
}

interface BookableDates {
  outward: string[];
  return: string[];
}

export interface BookingContextType {
  selectTickets: (ticket: BookingPackageRoomOption, packageDetail: BookingPackage | undefined, calculatePrice: boolean) => void;
  selectExtras: (extra: PerBookingPackageOption) => void;
  selectFlight: (selectedFlight: BookingPackageFlight) => void;
  selectHotel: (selectedHotel: BookingPackageHotel) => void;
  bookingSearch?: BookingPackageItem;
  setBookingSearch: (packageItem: BookingPackageItem) => void;
  bookingPackage?: BookingPackage;
  setBookingPackage: (booking: BookingPackage) => void;
  lastSuccesfulBookingPackage?: BookingPackage;
  bookingPriceDetail?: BookingPriceDetails;
  bookingAddress?: BookingPackageAddress;
  setBookingAddress: (address: BookingPackageAddress) => void;
  mainBookerData?: BookingPackagePax;
  setMainBookerData: (mainBookerData: BookingPackagePax) => void;
  mainBookerIsTraveller: string;
  setMainBookerIsTraveller: (mainBookerIsTraveller: string) => void;
  stayAtHome: BookingPackagePax;
  setStayAtHome: (stayAtHome: BookingPackagePax) => void;
  booking?: BookingPackageDossier;
  // book: (eventId: string, agentId?: number) => void;
  priceRecalculating?: string;
  reFetchPackageDetails: boolean;
  setReFetchPackageDetails: (bool: boolean) => void;
  noPackageFound: boolean;
  setNoPackageFound: (bool: boolean) => void;
  noHotelFound: boolean;
  setNoHotelFound: (bool: boolean) => void;
  flyInIsOpen: boolean;
  setFlyInIsOpen: (bool: boolean) => void;
  flyInHotel: string;
  setFlyInHotel: (hotelName: string) => void;
  flyInFacilities: TideItemForFacility[];
  setFlyInFacilities: (facilities: TideItemForFacility[]) => void;
  setRoomDistributionErrors: (errors: RoomDistributionError[]) => void;
  roomDistributionErrors: RoomDistributionError[];
  preNights?: number;
  postNights?: number;
  setPreNights: (nights?: number) => void;
  setPostNights: (nights?: number) => void;
  checkExternalAvailability: boolean;
  setCheckExternalAvailability: (bool: boolean) => void;
  requestRooms: BookingPackageRequestRoom[];
  setRequestRooms: (rooms: BookingPackageRequestRoom[]) => void;
  defaultTicketNotAvailable: boolean;
  setDefaultTicketNotAvailable: (ticketAvailable: boolean) => void;
  flightsLoaded: boolean;
  setFlightsLoaded: (flightsLoaded: boolean) => void;
  hotelsLoaded: boolean;
  setHotelsLoaded: (hotelsLoaded: boolean) => void;
  noFlightsFound: boolean;
  setNoFlightsFound: (noFlightsFound: boolean) => void;
  defaultFlightNotAvailable: boolean;
  setDefaultFlightNotAvailable: (hotelsAvailable: boolean) => void;
  flightPriceChanged: boolean;
  setFlightPriceChanged: (flightPriceChanged: boolean) => void;
  searchAltFlight: boolean;
  setSearchAltFlight: (searchAltFlight: boolean) => void;
  hotelPriceChanged: boolean;
  setHotelPriceChanged: (hotelPriceChanged: boolean) => void;
  searchAltHotel: boolean;
  setSearchAltHotel: (searchAltHotel: boolean) => void;
  bookEvent?: BookingEventRequest;
  setBookEvent: (bookingEventRequest: BookingEventRequest) => void;
  bookingPackageDossier?: BookingPackageDossier;
  activeOutwardDate?: string;
  setActiveOutwardDate: (date?: string) => void;
  activeReturnDate?: string;
  setActiveReturnDate: (date?: string) => void;
  bookableDates?: BookableDates;
  setBookableDates: (dates: BookableDates) => void;
  setIsDifferentDate: (isDifferentDate: boolean) => void;
  fetchPrice: boolean;
  setFetchPrice: (fetchPrice: boolean) => void;
  cachedTickets?: BookingPackageRoomOption[];
  setCachedTickets: (cachedTickets: BookingPackageRoomOption[]) => void;
  selectedTicket?: BookingPackageRoomOption;
  setSelectedTicket: (ticket: BookingPackageRoomOption | undefined) => void;
  cheapestTicket?: BookingPackageRoomOption;
  setCheapestTicket: (ticket: BookingPackageRoomOption | undefined) => void;
  shouldCheckTickets: boolean;
  setShouldCheckTickets: (shouldCheckTickets: boolean) => void;
  shouldCheckFlights: boolean;
  setShouldCheckFlights: (shouldCheckFlights: boolean) => void;
  activeStep: number;
  setActiveStep: (activeStep: number) => void;
  cachedFlightPool?: BookingPackageFlightPool;
  setCachedFlightPool: (flightPool: BookingPackageFlightPool) => void;
  selectedOutwardFlight?: BookingPackageFlight;
  // setSelectedOutwardFlight: (flight: BookingPackageFlight | undefined) => void;
  selectedReturnFlight?: BookingPackageFlight;
  // setSelectedReturnFlight: (flight: BookingPackageFlight | undefined) => void;
  cheapestFlight?: any;
  // setCheapestFlight: (flights: any) => void;
  showCachedPrices: boolean;
  nationalityCountries: CountryItem[];
  shouldCheckHotels: boolean;
  packageOnlyTicket: boolean;
}

const initialState: BookingContextType = {
  selectTickets: (ticket: BookingPackageRoomOption, packageDetail: BookingPackage | undefined, calculatePrice: boolean) => {},
  selectExtras: (extra: PerBookingPackageOption) => {},
  selectFlight: (selectedFlight: BookingPackageFlight) => {},
  selectHotel: (selectedHotel: BookingPackageHotel) => {},
  bookingSearch: undefined,
  setBookingSearch: (packageItem: BookingPackageItem) => {},
  bookingPackage: undefined,
  setBookingPackage: (booking: BookingPackage) => {},
  lastSuccesfulBookingPackage: undefined,
  bookingPriceDetail: undefined,
  bookingAddress: {} as BookingPackageAddress,
  setBookingAddress: (address: BookingPackageAddress) => {},
  mainBookerData: {} as BookingPackagePax,
  setMainBookerData: (mainBookerData: BookingPackagePax) => {},
  mainBookerIsTraveller: "1",
  setMainBookerIsTraveller: (mainBookerIsTraveller: string) => {},
  stayAtHome: {} as BookingPackagePax,
  setStayAtHome: (stayAtHome: BookingPackagePax) => {},
  booking: undefined,
  priceRecalculating: "",
  reFetchPackageDetails: false,
  setReFetchPackageDetails: (bool: boolean) => {},
  noPackageFound: false,
  setNoPackageFound: (bool: boolean) => {},
  noHotelFound: false,
  setNoHotelFound: (bool: boolean) => {},
  flyInIsOpen: false,
  setFlyInIsOpen: (bool: boolean) => {},
  flyInHotel: "",
  setFlyInHotel: (hotelName: string) => {},
  flyInFacilities: [] as TideItemForFacility[],
  setFlyInFacilities: (facilities: TideItemForFacility[]) => {},
  setRoomDistributionErrors: (errors: RoomDistributionError[]) => {},
  roomDistributionErrors: [] as RoomDistributionError[],
  preNights: undefined,
  postNights: 1,
  setPreNights: (nights?: number) => {},
  setPostNights: (nights?: number) => {},
  checkExternalAvailability: true,
  setCheckExternalAvailability: (bool: boolean) => {},
  requestRooms: DEFAULT_ROOM,
  setRequestRooms: (rooms: BookingPackageRequestRoom[]) => {},
  defaultTicketNotAvailable: false,
  setDefaultTicketNotAvailable: (ticketAvailable: boolean) => {},
  flightsLoaded: false,
  setFlightsLoaded: (flightsLoaded: boolean) => {},
  hotelsLoaded: false,
  setHotelsLoaded: (hotelsLoaded: boolean) => {},
  noFlightsFound: false,
  setNoFlightsFound: (noFlightsFound: boolean) => {},
  defaultFlightNotAvailable: false,
  setDefaultFlightNotAvailable: (hotelsAvailable: boolean) => {},
  flightPriceChanged: false,
  setFlightPriceChanged: (flightPriceChanged: boolean) => {},
  searchAltFlight: false,
  setSearchAltFlight: (searchAltFlight: boolean) => {},
  hotelPriceChanged: false,
  setHotelPriceChanged: (hotelPriceChanged: boolean) => {},
  searchAltHotel: false,
  setSearchAltHotel: (searchAltHotel: boolean) => {},
  bookEvent: undefined,
  setBookEvent: (bookingEventRequest: BookingEventRequest) => {},
  bookingPackageDossier: undefined,
  activeOutwardDate: "",
  setActiveOutwardDate: (date?: string) => {},
  activeReturnDate: "",
  setActiveReturnDate: (date?: string) => {},
  bookableDates: undefined,
  setBookableDates: (dates: BookableDates) => {},
  setIsDifferentDate: (isDifferentDate: boolean) => {},
  fetchPrice: false,
  setFetchPrice: (fetchPrice: boolean) => {},
  cachedTickets: [] as BookingPackageRoomOption[],
  setCachedTickets: (cachedTickets: BookingPackageRoomOption[]) => {},
  selectedTicket: undefined,
  setSelectedTicket: (ticket: BookingPackageRoomOption | undefined) => {},
  cheapestTicket: undefined,
  setCheapestTicket: (ticket: BookingPackageRoomOption | undefined) => {},
  shouldCheckTickets: true,
  setShouldCheckTickets: (shouldCheckTickets: boolean) => {},
  shouldCheckFlights: true,
  setShouldCheckFlights: (shouldCheckFlights: boolean) => {},
  activeStep: 1,
  setActiveStep: (activeStep: number) => {},
  cachedFlightPool: undefined,
  setCachedFlightPool: (flightPool: BookingPackageFlightPool) => {},
  selectedOutwardFlight: undefined,
  // setSelectedOutwardFlight: (flight: BookingPackageFlight | undefined) => {},
  selectedReturnFlight: undefined,
  // setSelectedReturnFlight: (flight: BookingPackageFlight | undefined) => {},
  cheapestFlight: undefined,
  // setCheapestFlight: (flights: any) => {},
  showCachedPrices: true,
  nationalityCountries: [],
  shouldCheckHotels: false,
  packageOnlyTicket: false,
};

const BookingContext = createContext<BookingContextType>(initialState);

export const BookingProvider: React.FC<BookingProviderProps> = (props) => {
  const { affiliate, member } = useContext<GlobalContextType>(GlobalContext);

  const [bookingSearch, setBookingSearch] = useState<BookingPackageItem>();
  const [bookingPackage, setBookingPackage] = useState<BookingPackage>();
  const [lastSuccesfulBookingPackage, setLastSuccesfulBookingPackage] = useState<BookingPackage>();
  const [bookingAddress, setBookingAddress] = useState<BookingPackageAddress>({
    name: "",
    street: "",
    number: "",
    box: "",
    postalCode: "",
    location: "",
    country: "Netherlands",
    mobilePhone: "",
    phone: "",
    email: "",
    vatNumber: "",
    customerType: 1,
  } as BookingPackageAddress);
  const [bookingPriceDetail, setBookingPriceDetail] = useState<BookingPriceDetails>();
  const [priceRecalculating, setPriceRecalculating] = useState<string>("");
  const [roomDistributionErrors, setRoomDistributionErrors] = useState([] as RoomDistributionError[]);
  const [reFetchPackageDetails, setReFetchPackageDetails] = useState<boolean>(false);
  const [checkExternalAvailability, setCheckExternalAvailability] = useState<boolean>(true);
  const [noPackageFound, setNoPackageFound] = useState<boolean>(false);
  const [noHotelFound, setNoHotelFound] = useState<boolean>(false);
  const [flyInIsOpen, setFlyInIsOpen] = useState<boolean>(false);
  const [flyInHotel, setFlyInHotel] = useState<string>("");
  const [flyInFacilities, setFlyInFacilities] = useState([] as TideItemForFacility[]);
  const [preNights, setPreNights] = useState<any>(undefined);
  const [postNights, setPostNights] = useState<any>(1);
  const [requestRooms, setRequestRooms] = useState<BookingPackageRequestRoom[]>(initialState.requestRooms);
  const [flightsLoaded, setFlightsLoaded] = useState<boolean>(false);
  const [hotelsLoaded, setHotelsLoaded] = useState<boolean>(false);
  const [noFlightsFound, setNoFlightsFound] = useState<boolean>(false);
  const [searchAltHotel, setSearchAltHotel] = useState<boolean>(false);
  const [searchAltFlight, setSearchAltFlight] = useState<boolean>(false);
  const [defaultTicketNotAvailable, setDefaultTicketNotAvailable] = useState<boolean>(false);
  const [defaultFlightNotAvailable, setDefaultFlightNotAvailable] = useState<boolean>(false);
  const [flightPriceChanged, setFlightPriceChanged] = useState<boolean>(false);
  const [hotelPriceChanged, setHotelPriceChanged] = useState<boolean>(false);
  const [fetchPrice, setFetchPrice] = useState<boolean>(false);
  const [isDifferentDate, setIsDifferentDate] = useState<boolean>(false);
  const [cachedFlightPool, setCachedFlightPool] = useState<BookingPackageFlightPool>();
  // const [flightPool, setFlightPool] = useState<BookingPackageFlightPool>();
  // const [hotelPool, setHotelPool] = useState<BookingPackageHotelPool>();
  const [bookEvent, setBookEvent] = useState<BookingEventRequest>();
  const [bookingPackageDossier, setBookingPackageDossier] = useState<BookingPackageDossier>();
  const [activeOutwardDate, setActiveOutwardDate] = useState<string | undefined>("");
  const [activeReturnDate, setActiveReturnDate] = useState<string | undefined>("");
  const [bookableDates, setBookableDates] = useState<BookableDates>();
  const [cachedTickets, setCachedTickets] = useState([] as BookingPackageRoomOption[]);
  const [selectedTicket, setSelectedTicket] = useState<BookingPackageRoomOption | undefined>(undefined);
  const [cheapestTicket, setCheapestTicket] = useState<BookingPackageRoomOption | undefined>(undefined);
  const [shouldCheckTickets, setShouldCheckTickets] = useState<boolean>(false);
  const [shouldCheckFlights, setShouldCheckFlights] = useState<boolean>(false);
  const [shouldCheckHotels, setShouldCheckHotels] = useState<boolean>(false);
  const [availableTickets, setAvailableTickets] = useState<BookingPackageAvailability[] | undefined>(undefined);
  const [activeStep, setActiveStep] = useState<number>(1);
  const [selectedOutwardFlight, setSelectedOutwardFlight] = useState<BookingPackageFlight | undefined>(undefined);
  const [selectedReturnFlight, setSelectedReturnFlight] = useState<BookingPackageFlight | undefined>(undefined);
  const [cheapestFlight, setCheapestFlight] = useState<any>(undefined);
  const [showCachedPrices, setShowCachedPrices] = useState<boolean>(true);
  const [switchToRealtime, setSwitchToRealtime] = useState<boolean>(false);
  const [packageWithFlights, setPackageWithFlights] = useState<boolean>(true);
  const [packageOnlyTicket, setPackageOnlyTickets] = useState<boolean>(false);
  const [flightsChecked, setFlightsChecked] = useState<boolean>(false);
  const [nationalityCountries, setNationalityCountries] = useState<CountryItem[]>([]);
  const [cachedPackagePrice, setCachedPackagePrice] = useState<number>(0);
  const [cachedFlightGuid, setCachedFlightGuid] = useState<string | undefined>(undefined);
  const [mainBookerIsTraveller, setMainBookerIsTraveller] = useState("1");
  const [mainBookerData, setMainBookerData] = useState({
    id: -1,
    dateOfBirth: "",
    age: 30,
    firstName: "",
    lastName: "",
    gender: 0,
    isMainBooker: true,
    initials: "",
    email: "",
    phone: "",
    mobilePhone: "",
    countryOfBirthId: 0,
  } as BookingPackagePax);
  const [stayAtHome, setStayAtHome] = useState<BookingPackagePax>({} as BookingPackagePax);
  const [booking] = useState<BookingPackageDossier>();

  let thereIsAFlightPriceDifference: boolean = false;
  let cachedFlightPrice: number = 0;

  const selectTickets = (ticket: BookingPackageRoomOption, packageDetail: BookingPackage | undefined, calculatePrice: boolean) => {
    if (ticket.entryLineGuid === "") {
      const tickets = cloneDeep(cachedTickets);
      for (const cachedTicket of tickets) {
        cachedTicket.isSelected = false;
        if (cachedTicket.tourCode === ticket.tourCode) {
          cachedTicket.isSelected = true;
        }
      }
      setCachedTickets(tickets);
    }

    if (packageDetail) {
      const detail = packageDetail?.options.find((o) => o.isSelected);
      if (packageDetail && detail) {
        for (const room of detail.rooms) {
          for (const option of room.options) {
            option.isSelected = false;
            if (option.tourCode == ticket.tourCode) {
              option.isSelected = true;
            }
          }
        }

        var groups = packageDetail.options.find((o) => o.isSelected)?.groups;
        if (groups) {
          for (const group of groups) {
            if (group.name === "CACHE TRESHOLD") {
              for (const option of group.options) {
                if (option.line.productCode === ticketReduction) {
                  option.isSelected = checkIfStillCachedTicket(ticket);
                }
              }
            }
          }
        }
        setBookingPackage(packageDetail);
        if (calculatePrice) {
          fetchPriceDetails(packageDetail, "tickets");
        }
      }
    }
    setSelectedTicket(ticket);
  };

  const checkIfStillCachedTicket = (selectedTicket: BookingPackageRoomOption | undefined) => {
    if (!bookingSearch) return false;
    if (!selectedTicket) return false;

    return bookingSearch.allotment?.tourCode == selectedTicket.tourCode;
  };

  const selectHotel = (selectedHotel: BookingPackageHotel) => {
    const detail = bookingPackage?.options.find((o) => o.isSelected);
    if (detail) {
      for (const hotel of detail.hotels) {
        hotel.isSelected = false;
        if (hotel.name == selectedHotel.name) {
          hotel.isSelected = true;
        }
      }

      const packageWithNewOptions = setBookingPackageUnitOptions(selectedHotel);

      if (packageWithNewOptions) {
        setBookingPackage({ ...packageWithNewOptions });
        fetchPriceDetails(packageWithNewOptions, "hotel");
      }
    }
  };

  const setBookingPackageUnitOptions = (selectedHotel: BookingPackageHotel) => {
    const detail = bookingPackage?.options.find((o) => o.isSelected);
    if (detail) {
      detail.optionUnits.forEach((x) => (x.groups = x.groups.filter((y) => !y.options.some((z) => z.isHotelPool))));
      for (var index = 0; index < detail.optionUnits.length; index++) {
        var unit = detail.optionUnits.find((x) => x.index == index + 1);
        if (!unit) {
          unit = {
            index: index + 1,
            groups: [],
            airlineGroups: [],
            airportGroups: [],
          };
          detail.optionUnits.push(unit);
        }
        var group = unit.groups.find((x) => x.name == "");
        if (!group) {
          group = {
            name: "",
            title: "",
            options: [],
          };
          unit.groups.push(group);
        }
        var alternatives: PerUnitPackageOption[] = [];
        selectedHotel.rooms.forEach((room) => {
          room.options
            .filter((o) => o.roomIndex == index + 1)
            .forEach((option) => {
              if (option.isSelected) {
              }
              var unitOption: PerUnitPackageOption = {
                alternatives: [],
                groups: [],
                isDefault: false,
                isHotelPool: true,
                isSelected: option.isSelected,
                line: {
                  accommodationCode: option.accommodationCode,
                  accommodationName: option.accommodationName,
                  endDate: detail.toDate,
                  entryLineGuid: option.entryLineGuid,
                  price: option.price,
                  productCode: selectedHotel.productCode,
                  productName: selectedHotel.name,
                  regimeCode: option.regimeCode,
                  regimeName: option.regimeName,
                  startDate: detail.fromDate,
                } as BookingPackageLine,
                pax: [],
                requirementType: 1,
              } as PerUnitPackageOption;
              alternatives.push(unitOption);
            });
        });

        var selected = alternatives.find((a) => a.isSelected);
        if (selected) {
          selected.alternatives = alternatives.filter((x) => x !== selected).map((a) => a.line);
          group.options.push(selected);
        }
      }

      // set the hotel reduction group if the selected hotel is the cached hotel
      if (bookingPackage) {
        var groups = bookingPackage.options.find((o) => o.isSelected)?.groups;
        if (groups) {
          for (const group of groups) {
            if (group.name === "CACHE TRESHOLD") {
              for (const option of group.options) {
                if (option.line.productCode === hotelReduction) {
                  option.isSelected = checkIfStillCachedHotel(selectedHotel);
                }
              }
            }
          }
        }
      }

      return bookingPackage;
    }
  };

  const selectExtras = (extra: PerBookingPackageOption) => {
    const detail = bookingPackage?.options.find((o) => o.isSelected);
    let packageExtra = first(detail?.groups.flatMap((g) => g.options.find((o) => o.line.entryLineGuid == extra.line.entryLineGuid)));
    if (packageExtra) {
      packageExtra.isSelected = !packageExtra.isSelected;
    }

    if (bookingPackage) {
      setBookingPackage({ ...bookingPackage });
      fetchPriceDetails(bookingPackage, "tickets");
    }
  };

  const selectFlight = (selectedFlight: BookingPackageFlight) => {
    if (cachedFlightPool) {
      let outwardFlight = selectedOutwardFlight;
      let returnFlight = selectedReturnFlight;
      for (const flight of cachedFlightPool.outwardFlights) {
        flight.isSelected = false;
        if (flight.externalGuid == selectedFlight.externalGuid) {
          flight.isSelected = true;
          outwardFlight = flight;
          setSelectedOutwardFlight(flight);
        }
      }
      for (const flight of cachedFlightPool.returnFlights) {
        flight.isSelected = false;
        if (flight.externalGuid == selectedFlight.externalGuid) {
          flight.isSelected = true;
          returnFlight = flight;
          setSelectedReturnFlight(flight);
        }
      }
      setCachedFlightPool({ ...cachedFlightPool });
      if (bookingPackage) {
        var groups = bookingPackage.options.find((o) => o.isSelected)?.groups;
        if (groups) {
          for (const group of groups) {
            if (group.name === "CACHE TRESHOLD") {
              for (const option of group.options) {
                if (option.line.productCode === flightReduction) {
                  option.isSelected = checkIfStillCachedFlight(outwardFlight, returnFlight);
                }
              }
            }
          }
        }
        fetchPriceDetails(bookingPackage, "flights");
      }
    }
  };

  const checkIfStillCachedFlight = (outwardFlight: BookingPackageFlight | undefined, returnFlight: BookingPackageFlight | undefined) => {
    // On the check with realtime data this cachedFlightGuid is filled in
    if (cachedFlightGuid) {
      if (outwardFlight?.entryLineGuid !== cachedFlightGuid) {
        return false;
      }
      return true;
    }

    // If not on realtime data, we need to check the searched flight from the beginning
    if (!bookingSearch) return false;
    if (
      bookingSearch.outwardFlightNumbers &&
      !isEqual(
        bookingSearch.outwardFlightNumbers,
        outwardFlight?.flightMetaData.flightLines.flatMap((fl) => fl.number)
      )
    ) {
      return false;
    }
    if (
      bookingSearch.returnFlightNumbers &&
      !isEqual(
        bookingSearch.returnFlightNumbers,
        returnFlight?.flightMetaData.flightLines.flatMap((fl) => fl.number)
      )
    ) {
      return false;
    }
    return true;
  };

  const fetchPackageDetails = async (provideFlights: boolean = false, signal: AbortSignal | undefined = undefined) => {
    let bookingPackageItem = cloneDeep(bookingSearch as BookingPackageItem);
    const detail = bookingPackage?.options.find((o) => o.isSelected);
    if (detail) {
      bookingPackageItem.catalogueId = detail.catalogueId;
      bookingPackageItem.code = detail.code;
      bookingPackageItem.fromDate = detail.fromDate;
      bookingPackageItem.toDate = detail.toDate;
    }
    if (props.queryParameters?.get("EventId")) {
      bookingPackageItem.allotment.tourCode = props.queryParameters?.get("EventId") ?? "";
    }
    if (provideFlights) {
      bookingPackageItem.outwardFlightCode = selectedOutwardFlight?.code;
      bookingPackageItem.outwardFlightNumbers = selectedOutwardFlight?.flightMetaData.flightLines.map((fl) => fl.number);
      bookingPackageItem.outwardFlightStartDate = selectedOutwardFlight?.startDateTime;
      bookingPackageItem.outwardFlightEndDate = selectedOutwardFlight?.endDateTime;
      bookingPackageItem.returnFlightCode = selectedReturnFlight?.code;
      bookingPackageItem.returnFlightNumbers = selectedReturnFlight?.flightMetaData.flightLines.map((fl) => fl.number);
      bookingPackageItem.returnFlightStartDate = selectedReturnFlight?.startDateTime;
      bookingPackageItem.returnFlightEndDate = selectedReturnFlight?.endDateTime;
    }

    const res = await searchPackageDetail(
      bookingPackageItem,
      checkExternalAvailability,
      requestRooms,
      preNights,
      postNights,
      isDifferentDate,
      provideFlights,
      signal
    );

    let packageDetail = res?.payload;

    if (packageDetail) {
      setNoPackageFound(false);

      // default namen wissen
      packageDetail.options.map((o) =>
        o.requestRooms.map((rr) =>
          rr.pax.map((p) => {
            p.firstName = "";
            p.lastName = "";
          })
        )
      );

      if (provideFlights) {
        setSelectedOutwardFlight(packageDetail.outwardFlights.find((o) => o.isSelected));
        setSelectedReturnFlight(packageDetail.returnFlights.find((o) => o.isSelected));

        // juiste ticket terug selecteren
        checkTicketsAvailability(packageDetail, requestRooms.flatMap((x) => x.pax).length, false);
      }
      setBookingPackage(packageDetail);
      setLastSuccesfulBookingPackage(packageDetail);
      setHotelsLoaded(true);
      await fetchPriceDetails(packageDetail, "price");
    } else {
      setNoPackageFound(true);
      if (!isEmpty(res.errorDetails)) {
        if (res.errorDetails?.reason == "No flights found") {
          setNoFlightsFound(true);
        }
      }
    }
    return packageDetail;
  };

  const fetchPriceDetails = async (pricePackage: BookingPackage, type: string) => {
    try {
      if (pricePackage && !showCachedPrices) {
        setPriceRecalculating(type);
        const priceDetail = await searchPackagePriceDetail(pricePackage);
        setBookingPriceDetail(priceDetail);
        setPriceRecalculating("");
      }
    } catch (error) {
      setNoPackageFound(true);
      setPriceRecalculating("");
    }
  };

  const checkTicketsAvailability = (packageDetail: BookingPackage | undefined, paxCount: number, calculatePrice: boolean = true) => {
    const detail = packageDetail?.options.find((o) => o.isSelected);
    const room = first(detail?.rooms);
    if (detail && room && !showCachedPrices) {
      let selectedOption = first(detail.rooms)?.options.find((o) => o.isSelected);
      let roomOptions = orderBy(first(detail.rooms)?.options, "price");
      if (roomOptions) {
        if (availableTickets) {
          roomOptions = roomOptions.filter(
            (r) =>
              availableTickets.find((a) => a.tourCode == r.tourCode) !== undefined &&
              (availableTickets.find((a) => a.tourCode == r.tourCode)?.count ?? 0) >= paxCount &&
              detail.availabilities.find((a) => a.tourCode == r.tourCode) !== undefined &&
              (detail.availabilities.find((a) => a.tourCode == r.tourCode)?.count ?? 0) >= paxCount
          );
          if (roomOptions.length === 0) return undefined;
        }

        selectedOption = roomOptions.find((r) => r.isSelected);
        // overwrite options in bookingPackage with selectedTicket if different
        if (selectedOption?.tourCode !== selectedTicket?.tourCode) {
          if (!roomOptions.find((o) => o.tourCode === selectedTicket?.tourCode)) {
            selectTickets(roomOptions[0], packageDetail, calculatePrice);
            setDefaultTicketNotAvailable(true);
            setShowCachedPrices(false);
          }

          room.options = roomOptions;
          for (const option of room.options) {
            if (option.tourCode === selectedTicket?.tourCode) {
              selectedOption = option;
            }
          }
        }
        if (selectedOption) {
          selectTickets(selectedOption, packageDetail, calculatePrice);
        }
        setCachedTickets(roomOptions);
        setCheapestTicket(first(orderBy(roomOptions, (r) => r.price)));
      }

      if (selectedOption) {
        var availabilty = detail?.availabilities.find((a) => a.tourCode == selectedOption?.tourCode);
        if (Number(availabilty?.count) < paxCount) {
          setDefaultTicketNotAvailable(true);
        }
      }
    } else {
      if (availableTickets) {
        const roomoptions = cachedTickets.filter(
          (c) =>
            availableTickets.find((a) => a.tourCode == c.tourCode) !== undefined &&
            (availableTickets.find((a) => a.tourCode == c.tourCode)?.count ?? 0) >= paxCount
        );
        setCachedTickets(roomoptions);
        if (!roomoptions.find((r) => r.isSelected) && !isEmpty(roomoptions)) {
          selectTickets(roomoptions[0], packageDetail, calculatePrice);
          setDefaultTicketNotAvailable(true);
        }
      }
    }
    return packageDetail;
  };

  const fillCachedTickets = (packageItems: BookingPackageItem[]) => {
    if (!packageItems || isEmpty(packageItems)) return;

    if (requestRooms && availableTickets) {
      const paxCount = requestRooms.flatMap((r) => r.pax).length;
      packageItems = packageItems.filter(
        (p) =>
          availableTickets.find((a) => a.tourCode == p.allotment.tourCode) !== undefined &&
          (availableTickets.find((a) => a.tourCode == p.allotment.tourCode)?.count ?? 0) >= paxCount
      );
    }

    // only select cheapest option from same category
    let filteredRooms = [] as BookingPackageItem[];
    for (const [key, value] of Object.entries(groupBy(packageItems, (p) => p.accommodationCode))) {
      const roomOption = first(orderBy(value, "price"));
      if (roomOption) {
        filteredRooms.push(roomOption);
      }
    }

    const roomOptions = filteredRooms.map((item) => {
      return {
        entryLineGuid: "",
        productCode: item.code,
        accommodationCode: item.accommodationCode,
        externalAccommodationCode: item.externalAccommodationCode,
        accommodationName: item.accommodationName,
        regimeCode: item.regimeCode,
        regimeName: "",
        price: item.price,
        isSelected: first(packageItems)?.allotment.tourCode === item.allotment.tourCode,
        from: item.allotment.startDate,
        to: item.allotment.endDate,
        tourCode: item.allotment.tourCode,
      } as BookingPackageRoomOption;
    });
    setCachedTickets(roomOptions);
    setSelectedTicket(roomOptions.find((r) => r.isSelected));
    setCheapestTicket(first(orderBy(roomOptions, (r) => r.price)));

    return filteredRooms;
  };

  const checkFlightsAvailability = (packageDetail: BookingPackage) => {
    if (!packageWithFlights) {
      return;
    }

    if (isEmpty(packageDetail.outwardFlights) || isEmpty(packageDetail.returnFlights)) {
      setShouldCheckHotels(false);
      setNoFlightsFound(true);
      setCachedFlightPool({ outwardFlights: [], returnFlights: [] });
      return;
    }

    fillFlightPool({ outwardFlights: packageDetail.outwardFlights, returnFlights: packageDetail.returnFlights }, true);

    // set the flight reduction group if the selected flight is the cached flight
    if (packageDetail) {
      var groups = packageDetail.options.find((o) => o.isSelected)?.groups;
      if (groups) {
        for (const group of groups) {
          if (group.name === "CACHE TRESHOLD") {
            for (const option of group.options) {
              if (option.line.productCode === flightReduction) {
                option.isSelected = checkIfStillCachedFlight(selectedOutwardFlight, selectedReturnFlight);
              }
            }
          }
        }
      }
      setBookingPackage({ ...packageDetail });
    }
  };

  const checkAndSetSelectedFlight = (flightPool: BookingPackageFlightPool) => {
    const foundOutwardFlights = flightPool.outwardFlights.filter((f) =>
      isEqual(
        f.flightMetaData.flightLines.flatMap((fl) => fl.number),
        selectedOutwardFlight?.flightMetaData.flightLines.flatMap((fl) => fl.number)
      )
    );
    const foundReturnFlights = flightPool.returnFlights.filter((f) =>
      isEqual(
        f.flightMetaData.flightLines.flatMap((fl) => fl.number),
        selectedReturnFlight?.flightMetaData.flightLines.flatMap((fl) => fl.number)
      )
    );
    let equalOutwardFlight = undefined;
    let equalReturnFlight = undefined;
    if (!isEmpty(foundOutwardFlights) && !isEmpty(foundReturnFlights)) {
      for (const outwardFlight of foundOutwardFlights) {
        const returnFlight = foundReturnFlights.find((r) => r.externalGuid === outwardFlight.externalGuid);
        if (returnFlight) {
          equalOutwardFlight = outwardFlight;
          equalReturnFlight = returnFlight;
          break;
        }
      }
    }

    if (!equalOutwardFlight || !equalReturnFlight) {
      setShouldCheckHotels(false);
      setDefaultFlightNotAvailable(true);
      setSelectedOutwardFlight(flightPool.outwardFlights.find((o) => o.isSelected));
      setSelectedReturnFlight(flightPool.returnFlights.find((o) => o.isSelected));
      setCheapestFlight({
        outwardFlight: flightPool.outwardFlights.find((o) => o.isSelected),
        returnFlight: flightPool.returnFlights.find((o) => o.isSelected),
      });
      return flightPool;
    }

    // set the selectedFlight in the flightpool
    for (const flight of flightPool.outwardFlights) {
      flight.isSelected = false;
      if (flight.externalGuid == equalOutwardFlight.externalGuid) {
        flight.isSelected = true;
      }
    }
    // set the selectedFlight in the flightpool
    for (const flight of flightPool.returnFlights) {
      flight.isSelected = false;
      if (flight.externalGuid == equalReturnFlight.externalGuid) {
        flight.isSelected = true;
      }
    }

    if (
      (selectedOutwardFlight && selectedOutwardFlight.price !== equalOutwardFlight.price) ||
      (selectedReturnFlight && selectedReturnFlight.price !== equalReturnFlight.price)
    ) {
      // with cached flight there is a difference bigger then the treshold so a popup should be shown.
      if (checkIfStillCachedFlight(selectedOutwardFlight, selectedReturnFlight)) {
        setShouldCheckHotels(false);
        setFlightPriceChanged(true);
      } else {
        thereIsAFlightPriceDifference = true;
      }
    }

    setSelectedOutwardFlight(flightPool.outwardFlights.find((o) => o.isSelected));
    setSelectedReturnFlight(flightPool.returnFlights.find((o) => o.isSelected));
    // const cheapestOutwarFlight = first(orderBy(flightPool.outwardFlights, "price"));
    // setCheapestFlight({
    //   outwardFlight: cheapestOutwarFlight,
    //   returnFlight: flightPool.returnFlights.find((o) => o.externalGuid == cheapestOutwarFlight?.externalGuid),
    // });
    // setShouldCheckHotels(true);
    return flightPool;
  };

  const fillFlightPool = (flightPool: BookingPackageFlightPool, doFlightCheck: boolean) => {
    if (!flightPool) {
      return;
    }

    if (doFlightCheck && selectedOutwardFlight && !isEmpty(flightPool.outwardFlights) && selectedReturnFlight && !isEmpty(flightPool.returnFlights)) {
      flightPool = checkAndSetSelectedFlight(flightPool);
    }

    setCachedFlightPool(flightPool);

    if (!doFlightCheck) {
      let flights = [];
      if (flightPool.outwardFlights) {
        for (const outwardFlight of flightPool.outwardFlights) {
          let matchingReturnFlight = flightPool.returnFlights.find((rf) => rf.externalGuid == outwardFlight.externalGuid);
          if (matchingReturnFlight) {
            flights.push({
              outwardFlight: outwardFlight,
              returnFlight: matchingReturnFlight,
            });
          }
        }
        if (bookingSearch?.includedServiceTypes?.includes(ServiceType.flight) && !isEmpty(flights)) {
          const cheapestFlight = flights?.reduce(function (prev, curr) {
            return prev.outwardFlight.price + prev.returnFlight.price < curr.outwardFlight.price + curr.returnFlight.price ? prev : curr;
          });
          setCheapestFlight(cheapestFlight);
        }
      }
    }
  };

  const getCachedPackagePrice = () => {
    let difference = 0;
    if (selectedTicket && cheapestTicket) {
      difference = selectedTicket.price - cheapestTicket.price;
    }

    let flightPriceDifference = 0;
    if (selectedOutwardFlight && selectedReturnFlight && cheapestFlight) {
      flightPriceDifference =
        selectedOutwardFlight.price + selectedReturnFlight.price - (cheapestFlight.outwardFlight.price + cheapestFlight.returnFlight.price);
    }

    return bookingSearch && bookingSearch.price ? bookingSearch.price + difference + flightPriceDifference : 0;
  };

  const checkHotelPrice = () => {
    const realtimePackagePrice = sumBy(bookingPriceDetail?.details, "total");
    if (realtimePackagePrice.toFixed(2) !== cachedPackagePrice.toFixed(2)) {
      setHotelPriceChanged(true);
      return;
    }
  };

  const checkIfStillCachedHotel = (selectedHotel: BookingPackageHotel | undefined) => {
    if (!bookingSearch) return false;
    if (!selectedHotel) return false;

    return bookingSearch.hotelProductCode == selectedHotel.productCode;
  };

  const mapStepsForDataLayer = () => {
    switch (activeStep) {
      case 1:
        return "choose_group_and_dates";
      case 2:
        return "select_ticket_and_extras";
      case 3:
        return "select_flights";
      case 4:
        return "select_hotel";
      case 5:
        return "fill_in_data";
      case 6:
        return "booking_overview";
      default:
        return "booking";
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    (async () => {
      try {
        const countries = await searchCountries(signal);
        if (countries) {
          setNationalityCountries(countries);
        }
      } catch (error) {
        console.log(error);
      }
    })();
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    (async () => {
      if (props.queryParameters) {
        try {
          const serviceTypes = props.queryParameters
            .get("ServiceTypes")
            ?.split(",")
            .map((n) => parseInt(n, 10));

          setPackageWithFlights(serviceTypes?.includes(ServiceType.flight) ?? true);
          setPackageOnlyTickets((!serviceTypes?.includes(ServiceType.hotel) && !serviceTypes?.includes(ServiceType.flight)) ?? false);

          let packageItems: BookingPackageItem[] = [];
          if (props.queryParameters?.get("EventId")) {
            const eventId = props.queryParameters.get("EventId") as string;
            const tourcodes = (await getTourcodes([eventId])).tourCodes;
            setNoHotelFound(false);
            const response = !isEmpty(tourcodes)
              ? await searchSpecific(
                  tourcodes,
                  [],
                  requestRooms,
                  props.queryParameters?.get("From") ?? "",
                  props.queryParameters?.get("To") ?? "",
                  undefined,
                  serviceTypes?.includes(ServiceType.flight) ?? true,
                  signal
                )
              : ({} as TideResponse<BookingPackageItem[]>);

            if (response?.payload && !isEmpty(response.payload)) {
              packageItems = response.payload;
            }

            if (response.errorCode || isEmpty(packageItems)) {
              if (response.errorDetails?.reason === "No hotel found") {
                setNoHotelFound(true);
              } else {
                setNoPackageFound(true);
              }
            }
          }
          if (packageItems) {
            const filteredPackages = fillCachedTickets(packageItems);
            if (filteredPackages) {
              const packageItem = first(filteredPackages);
              if (packageItem?.allotment.startDate && packageItem.fromDate) {
                setPreNights(
                  differenceInCalendarDays(
                    new Date(packageItem.allotment.startDate.replace("Z", "")),
                    new Date(packageItem.fromDate.replace("Z", ""))
                  )
                );
              }
              if (packageItem?.allotment.endDate && packageItem.toDate) {
                setPostNights(
                  differenceInCalendarDays(new Date(packageItem.toDate.replace("Z", "")), new Date(packageItem.allotment.endDate.replace("Z", "")))
                );
              }
              setBookingSearch(packageItem);
              if (packageItem && window?.dataLayer) {
                window.dataLayer.push({
                  event: "begin_checkout",
                  ecommerce: {
                    currency: "EUR",
                    value: getCachedPackagePrice(),
                    step: activeStep,
                    agent: member?.agentId,
                    travelCompany: {
                      adults: requestRooms.flatMap((r) => r.pax.filter((p) => p.age! >= 12)).length,
                      children: requestRooms.flatMap((r) => r.pax.filter((p) => p.age! < 12)).length,
                    },
                    items: createDataLayerItemsFromSearch(packageItem, requestRooms),
                  },
                });
              }
              setShowCachedPrices(true);

              const availabilities = await getTicketAvailabilities(
                props.queryParameters?.get("EventId") ?? "",
                props.queryParameters?.get("ProductCode") ?? "",
                signal
              );
              setAvailableTickets(availabilities);
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    })();
    return () => {
      controller.abort();
    };
  }, [props.queryParameters]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    (async () => {
      if (bookingSearch) {
        let bookingPackageItem = bookingSearch as BookingPackageItem;
        if (reFetchPackageDetails) {
          setPriceRecalculating("all");
          setBookingPriceDetail(undefined);
          setCachedFlightPool(undefined);
          setFlightsLoaded(false);
          setHotelsLoaded(false);
          setShowCachedPrices(true);
          setBookingPackage(undefined);
          try {
            if (props.queryParameters?.get("EventId") && activeOutwardDate && activeReturnDate) {
              const eventId = props.queryParameters?.get("EventId") as string;
              const tourcodes = (await getTourcodes([eventId])).tourCodes;
              let packageItems: BookingPackageItem[] = [];
              setNoHotelFound(false);
              const response = !isEmpty(tourcodes)
                ? await searchSpecific(
                    tourcodes,
                    [],
                    requestRooms,
                    activeOutwardDate,
                    activeReturnDate,
                    undefined,
                    bookingPackageItem.includedServiceTypes?.includes(ServiceType.flight),
                    signal
                  )
                : ({} as TideResponse<BookingPackageItem[]>);

              if (response?.payload && !isEmpty(response.payload)) {
                packageItems = response.payload;
              }

              if (response.errorCode || isEmpty(packageItems)) {
                if (response.errorDetails?.reason === "No hotel found") {
                  setNoHotelFound(true);
                } else {
                  setNoPackageFound(true);
                }
              }

              if (!isEmpty(packageItems)) {
                const filteredPackages = fillCachedTickets(packageItems);
                if (filteredPackages && filteredPackages.length > 0) {
                  setBookingSearch(first(filteredPackages));
                } else {
                  setNoPackageFound(true);
                }
              }
              setPriceRecalculating("");
            }
          } catch (error) {
            console.log(error);
            setNoPackageFound(true);
            setPriceRecalculating("");
          } finally {
            setReFetchPackageDetails(false);
          }
        }
      }
    })();
    return () => {
      controller.abort();
    };
  }, [reFetchPackageDetails, requestRooms, activeOutwardDate, activeReturnDate]);

  useEffect(() => {
    if (fetchPrice && lastSuccesfulBookingPackage) {
      fetchPriceDetails(lastSuccesfulBookingPackage, "price");
    }
    setFetchPrice(false);
  }, [fetchPrice]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    (async () => {
      if (bookingSearch) {
        try {
          fetchPackageDetails(false, signal);
        } catch (error) {
          console.log(error);
        } finally {
          setReFetchPackageDetails(false);
          setCheckExternalAvailability(false);
        }
      }
    })();
    return () => {
      controller.abort();
    };
  }, [bookingSearch]);

  // cached flights ophalen
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    (async () => {
      if (bookingSearch) {
        if (packageWithFlights) {
          try {
            const bookingPackageFlightPool = await getFlightPool(bookingSearch, requestRooms, false, signal);
            if (bookingPackageFlightPool) {
              setSelectedOutwardFlight(bookingPackageFlightPool.outwardFlights.find((o) => o.isSelected));
              setSelectedReturnFlight(bookingPackageFlightPool.returnFlights.find((o) => o.isSelected));

              fillFlightPool(bookingPackageFlightPool, false);
              setFlightsLoaded(true);
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
    })();
    return () => {
      controller.abort();
    };
  }, [bookingSearch]);

  // checks between different steps
  useEffect(() => {
    if (activeStep > 2 && shouldCheckTickets) {
      if (availableTickets) {
        setShouldCheckTickets(false);
      }
      const newBookingPackage = checkTicketsAvailability(bookingPackage, requestRooms.flatMap((x) => x.pax).length, true);
      if (bookingPackage && !newBookingPackage) {
        setNoPackageFound(true);
      }
      setLastSuccesfulBookingPackage(newBookingPackage);
      setBookingPackage(newBookingPackage);
    }

    // nieuwe weg inslaan om eventueel nogmaals een details call te doen en dan de checks uit te voeren op vluchten en hotel
    if (activeStep > 3 && showCachedPrices && bookingPackage) {
      // we only do this call once
      setShowCachedPrices(false);
      // call the method async via a useEffect so we can control the order of tests.
      setSwitchToRealtime(true);
      setShouldCheckHotels(!packageOnlyTicket);
    }
  }, [activeStep, availableTickets, bookingPackage]);

  // Control the selected flight and hotel from cache against the realtime prices
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    (async () => {
      // some variables to handle the async calls better
      let flightsAllreadyInDifference = false;

      if (switchToRealtime && bookingPackage) {
        setFlightsChecked(false);
        setSwitchToRealtime(false);
        setShouldCheckFlights(false);
        cachedFlightPrice = (selectedOutwardFlight?.price ?? 0) + (selectedReturnFlight?.price ?? 0);
        setCachedPackagePrice(getCachedPackagePrice());
        checkFlightsAvailability(bookingPackage);

        // refetch the details to determine the cache treshold on the flights
        if (thereIsAFlightPriceDifference) {
          try {
            thereIsAFlightPriceDifference = false;
            const packageDetail = await fetchPackageDetails(true, signal);
            const outwardFlight = packageDetail.outwardFlights.find((o) => o.isSelected);
            const returnFlight = packageDetail.returnFlights.find((o) => o.isSelected);
            setCachedFlightGuid(outwardFlight?.entryLineGuid);
            setCachedFlightPool({ outwardFlights: packageDetail.outwardFlights, returnFlights: packageDetail.returnFlights });

            // check flight differences again because treshold is now calculated on new selected flight
            if ((outwardFlight && outwardFlight.price !== cachedFlightPrice / 2) || (returnFlight && returnFlight.price !== cachedFlightPrice / 2)) {
              flightsAllreadyInDifference = true;
              setFlightPriceChanged(true);
              setShouldCheckHotels(false);

              setSelectedOutwardFlight(packageDetail.outwardFlights.find((o) => o.isSelected));
              setSelectedReturnFlight(packageDetail.returnFlights.find((o) => o.isSelected));
              const cheapestOutwarFlight = first(orderBy(packageDetail.outwardFlights, "price"));
              setCheapestFlight({
                outwardFlight: cheapestOutwarFlight,
                returnFlight: packageDetail.returnFlights.find((o) => o.externalGuid == cheapestOutwarFlight?.externalGuid),
              });
              return;
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          checkTicketsAvailability(bookingPackage, requestRooms.flatMap((x) => x.pax).length, false);
          setBookingPackage(bookingPackage);
          await fetchPriceDetails(bookingPackage, "price");
        }
        setFlightsChecked(true);
      }

      // check hotel prices if the pricedetails is finished.
      if (shouldCheckHotels && bookingPackage && bookingPriceDetail && flightsChecked) {
        setShouldCheckHotels(false);
        checkHotelPrice();
      }
    })();
  }, [switchToRealtime, bookingPackage, bookingPriceDetail, flightsChecked]);

  useEffect(() => {
    (async () => {
      if (bookEvent?.eventId) {
        try {
          const bookingPackageDossier = await bookPackage(
            bookingPackage as BookingPackage,
            bookingPriceDetail as BookingPriceDetails,
            stayAtHome,
            mainBookerData,
            bookingAddress,
            bookEvent?.eventId,
            affiliate,
            props.tradeTrackerTag,
            bookEvent?.agentId
          );

          if (bookingPackageDossier && window?.dataLayer) {
            window.dataLayer.push({
              event: "offerte",
              ecommerce: {
                currency: "EUR",
                transaction_id: bookingPackageDossier.number,
                value: sumBy(bookingPriceDetail?.details, "total"),
                affiliation: affiliate,
                agent: member?.agentId,
                travelCompany: {
                  adults: requestRooms.flatMap((r) => r.pax.filter((p) => p.age! >= 12)).length,
                  children: requestRooms.flatMap((r) => r.pax.filter((p) => p.age! < 12)).length,
                },
                items: createDataLayerItemsFromDetails(bookingPackage),
              },
            });
            if (bookEvent.agentId) {
              setBookingPackageDossier(bookingPackageDossier);
            } else {
              navigate(bookingPackageDossier.paymentUrl);
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    })();
  }, [bookEvent]);

  // fill datalayer with bookingFlow steps
  useEffect(() => {
    if (window?.dataLayer) {
      if (!showCachedPrices && bookingPackage && bookingPriceDetail) {
        window.dataLayer.push({
          event: mapStepsForDataLayer(),
          ecommerce: {
            currency: "EUR",
            value: sumBy(bookingPriceDetail?.details, "total"),
            step: activeStep,
            agent: member?.agentId,
            travelCompany: {
              adults: requestRooms.flatMap((r) => r.pax.filter((p) => p.age! >= 12)).length,
              children: requestRooms.flatMap((r) => r.pax.filter((p) => p.age! < 12)).length,
            },
            items: createDataLayerItemsFromDetails(bookingPackage),
          },
        });
      } else if (showCachedPrices && bookingSearch) {
        window.dataLayer.push({
          event: mapStepsForDataLayer(),
          ecommerce: {
            currency: "EUR",
            value: getCachedPackagePrice(),
            step: activeStep,
            agent: member?.agentId,
            travelCompany: {
              adults: requestRooms.flatMap((r) => r.pax.filter((p) => p.age! >= 12)).length,
              children: requestRooms.flatMap((r) => r.pax.filter((p) => p.age! < 12)).length,
            },
            items: createDataLayerItemsFromSearch(bookingSearch, requestRooms),
          },
        });
      }
    }
  }, [activeStep, bookingSearch, bookingPackage, bookingPriceDetail]);

  return (
    <BookingContext.Provider
      value={{
        bookingPackage,
        lastSuccesfulBookingPackage,
        bookingPriceDetail,
        selectTickets,
        selectExtras,
        selectFlight,
        selectHotel,
        setBookingPackage,
        setBookingSearch,
        bookingSearch,
        bookingAddress,
        setBookingAddress,
        mainBookerData,
        setMainBookerData,
        mainBookerIsTraveller,
        setMainBookerIsTraveller,
        stayAtHome: stayAtHome,
        setStayAtHome: setStayAtHome,
        booking,
        priceRecalculating,
        reFetchPackageDetails,
        setReFetchPackageDetails,
        noPackageFound,
        setNoPackageFound,
        noHotelFound,
        setNoHotelFound,
        flyInIsOpen,
        setFlyInIsOpen,
        flyInHotel,
        setFlyInHotel,
        flyInFacilities,
        setFlyInFacilities,
        setRoomDistributionErrors,
        roomDistributionErrors,
        preNights,
        postNights,
        setPreNights,
        setPostNights,
        checkExternalAvailability,
        setCheckExternalAvailability,
        requestRooms,
        setRequestRooms,
        flightsLoaded,
        hotelsLoaded,
        setFlightsLoaded,
        setHotelsLoaded,
        bookEvent,
        setBookEvent,
        bookingPackageDossier,
        activeOutwardDate,
        setActiveOutwardDate,
        activeReturnDate,
        setActiveReturnDate,
        bookableDates,
        setBookableDates,
        hotelPriceChanged,
        setHotelPriceChanged,
        defaultFlightNotAvailable,
        setDefaultFlightNotAvailable,
        flightPriceChanged,
        setFlightPriceChanged,
        noFlightsFound,
        setNoFlightsFound,
        searchAltHotel,
        setSearchAltHotel,
        searchAltFlight,
        setSearchAltFlight,
        setIsDifferentDate,
        defaultTicketNotAvailable,
        setDefaultTicketNotAvailable,
        fetchPrice,
        setFetchPrice,
        cachedTickets,
        setCachedTickets,
        selectedTicket,
        setSelectedTicket,
        cheapestTicket,
        setCheapestTicket,
        shouldCheckTickets,
        setShouldCheckTickets,
        shouldCheckFlights,
        setShouldCheckFlights,
        activeStep,
        setActiveStep,
        cachedFlightPool,
        setCachedFlightPool,
        selectedOutwardFlight,
        selectedReturnFlight,
        cheapestFlight,
        showCachedPrices,
        nationalityCountries,
        shouldCheckHotels,
        packageOnlyTicket,
      }}
    >
      {props.children}
    </BookingContext.Provider>
  );
};

export default BookingContext;
